<template>
  <div class="email-app-details">
    <!-- Share Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-share-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          <b-row>
            <b-col
              md="12"
              class="agent"
            >
              {{ agent }}
            </b-col>
          </b-row>
        </h4>
      </div>
    </div>
    <div class="email-app-list ecommerce-application position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchListingsTxt"
              value=""
              :placeholder="listview_txt_default.txt_listings_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="sortByOptions.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in sortByOptions"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>

      <!-- Listings -->
      <vue-perfect-scrollbar
        ref="filterScroll"
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >
        <div class="grid-view m-0 p-1">
          <b-card
            v-for="(listing, index) in listviewListings"
            :key="`listing-${index}`"
            class="ecommerce-card"
            no-body
          >
            <div class="item-img text-center pt-0">
              <b-link>
                <b-img
                  :alt="`${listing.pid}`"
                  fluid
                  class="card-img-top"
                  :src="listing.image + '?v=' + new Date().getTime()"
                />
              </b-link>
            </div>

            <!-- Listing Details -->
            <b-card-body>
              <h6 class="item-name mt-75 mb-75">
                <b-link
                  class="text-body"
                >
                  {{ listing.pid }}
                </b-link>
              </h6>
              <b-card-text class="item-description mt-0 mb-0">
                {{ listing.morada }}
              </b-card-text>
              <b-card-text class="item-description mt-0 mb-0">
                <b>{{ listing.nickname }}</b>
              </b-card-text>
              <b-card-text class="item-description mt-1 mb-0">
                <b-media>
                  <template #aside>
                    <b-avatar :src="`${listing.imgAgent}`" />
                  </template>
                  <h6>{{ listing.nameAgent }} - {{ listing.moradaAgent }}</h6>
                  <p class="mb-0">
                    {{ listing.phoneAgent }}
                  </p>
                </b-media>
              </b-card-text>
              <div class="mt-75">
                <b-button
                  v-if="listing.versoes[0].desState !== ''"
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  class="btn-sm mr-25"
                >
                  {{ listing.versoes[0].desState }} {{ listing.versoes[0].version }}
                </b-button>
                <b-button
                  v-if="(typeof listing.versoes[1] !== 'undefined')"
                  v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                  variant="flat-warning"
                  class="btn-sm mr-25"
                >
                  {{ listing.versoes[1].desState }} {{ listing.versoes[1].version }}
                </b-button>
              </div>
            </b-card-body>

            <!-- Listing Actions -->
            <div class="item-options text-center">
              <div class="item-wrapper">
                <div class="item-cost">
                  <h4 class="item-price">
                    {{ listing.currencyL }}{{ formatPrice(listing.preco) }}{{ listing.currencyR }}
                  </h4>
                </div>
              </div>
              <b-button
                variant="primary"
                tag="a"
                class="btn-cart"
                target="_blank"
                :href="listing.urlShare"
              >
                <feather-icon
                  icon="Share2Icon"
                  class="mr-50"
                />
                <span>{{ $t('Ver partilha') }}</span>
              </b-button>
              <b-button-group>
                <b-button
                  v-if="listing.urlShare"
                  v-clipboard:copy="listing.urlShare"
                  variant="primary"
                  tag="a"
                  class="btn-cart"
                  @click="copyClipboard()"
                >
                  <feather-icon
                    icon="CopyIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('Copiar link') }}</span>
                </b-button>
              </b-button-group>
            </div>
          </b-card>
        </div>
        <div
          class="no-results"
          :class="{'show': !listviewListings.length}"
        >
          <h5 v-if="listviewListingsInit === 0">
            {{ listview_txt_default.txt }}
          </h5>
          <h5 v-if="listviewListingsInit === 1">
            {{ listview_txt_default.txt_loading }}
          </h5>
        </div>
      </vue-perfect-scrollbar>

      <b-container
        v-if="listviewListings.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="listingsCurrentPage"
              :total-rows="listingsTotalRows"
              :per-page="listingsPerPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['share/loadingListviewListings']"
        no-wrap
      />

    </div>

  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend,
  VBTooltip, BDropdown, BDropdownItem, BRow, BCol, BContainer, BPagination, BOverlay,
  BCard, BCardBody, BLink, BImg, BCardText, BButton, BButtonGroup, BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import shareModule from '@store-modules/listings/share/index'
import { mapGetters } from 'vuex'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'
import { configSettingsScroll, formatPrice } from '@core-custom/utils/ui'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BContainer,
    BPagination,
    BOverlay,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BButtonGroup,
    BMedia,
    BAvatar,
    VuePerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      formatPrice,
    }
  },
  computed: {
    ...mapGetters('share', ['listviewListings', 'listviewListingsInit', 'listviewListingsOrderBy', 'sortByOptions', 'agent', 'listview_txt_default', 'listingsTotalRows', 'listingsPerPage']),
    ...mapGetters('listings', ['pageSel']),
    searchListingsTxt: {
      get() { return this.$store.getters['share/searchListingsTxt'] },
      set(newValue) {
        this.$store.dispatch('share/setSearchListingsTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
    sizeIconsAction() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? '21' : '17')
    },
    listingsCurrentPage: {
      get() { return this.$store.getters['share/listingsCurrentPage'] },
      set(newValue) {
        this.$store.dispatch('share/setListingsCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
  },
  watch: {
    $route() {
      this.$refs.leftSidebar.$refs.filterScroll.$el.scrollTop = 0
    },
  },
  beforeDestroy() {
    if (this.$refs.listviewScroll) {
      this.$refs.listviewScroll.destroy()
    }
  },
  mounted() {
    if (useCapacitor.isNative() === true) {
      document.body.classList.add('capacitor')
    }
  },
  destroyed() {
    if (useCapacitor.isNative() === true) {
      document.body.classList.remove('capacitor')
    }
  },
  methods: {
    listviewFilterOrderBy(field) {
      this.$store.dispatch('share/listviewListingsFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewListingsOrderBy.column === filterField) {
        if (this.listviewListingsOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    copyClipboard() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$t('Sucesso'),
          icon: 'CheckIcon',
          variant: 'success',
          text: this.$t('Link copiado para o clipboard'),
        },
      })
    },
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
  },
  setup() {
    const SHARE_MODULE_NAME = 'share'

    if (!store.hasModule(SHARE_MODULE_NAME)) {
      store.registerModule(SHARE_MODULE_NAME, shareModule)

      onUnmounted(() => {
        if (store.hasModule(SHARE_MODULE_NAME)) store.unregisterModule(SHARE_MODULE_NAME)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      mqShallShowLeftSidebar,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.email-application .email-app-details {
  z-index: 3!important;
  width: 100%!important;
}

.email-app-list.space-bottom{
  padding-bottom: 2.8rem;

  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
}

.ecommerce-application .email-app-details .email-user-list {
  height: calc( 100% - calc(9rem) )!important;
}

.ecommerce-application .app-fixed-search {
  padding: 0 0 0 0!important;
}

.ecommerce-application .app-fixed-search input {
  margin-top: 0.4rem;
}

.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
