<template>
  <div style="height: inherit">
    <div class="email-app-list position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div
        class="app-fixed-search d-flex align-items-center"
      >
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="fieldsOrderAgents.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in fieldsOrderAgents"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>
      <!-- Share Details -->
      <vue-perfect-scrollbar
        ref="listviewData"
        class="email-user-list scroll-area"
        :settings="configSettingsScroll"
      >
        <listview-list-data
          v-if="listviewData.length > 0"
          ref="list"
        />
        <div
          v-if="listviewData.length === 0"
          class="no-results"
          :class="{'show': !listviewData.length}"
        >
          <h5 v-if="listviewInit === 0">
            {{ listview_txt_default.txt }}
          </h5>
          <h5 v-if="listviewInit === 1">
            {{ listview_txt_default.txt_loading }}
          </h5>
        </div>
      </vue-perfect-scrollbar>

      <b-container
        v-if="listviewData.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['share/loadingListview']"
        no-wrap
      />

    </div>

    <!-- Share View/Detail -->
    <share-view
      :class="{'show': showListingShareDetails}"
      @close-share-view="setShowListingShareDetails([false, null, null])"
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem, BContainer, BPagination, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import shareModule from '@store-modules/listings/share/index'
import { mapGetters } from 'vuex'
import { Dialog } from '@capacitor/dialog'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import useCapacitor from '@core-custom/utils/capacitor'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll } from '@core-custom/utils/ui'
import ListviewListData from './listData.vue'
import ShareView from './listviewListingsShareUser.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BContainer,
    BPagination,
    BOverlay,
    ListviewListData,
    VuePerfectScrollbar,

    ShareView,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [sizeIconsAction],
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('share', ['listviewData', 'listviewInit', 'listview_txt_default', 'listviewOrderBy', 'fieldsOrderAgents', 'totalRows', 'perPage', 'showListingShareDetails']),
    searchTxt: {
      get() { return this.$store.getters['share/searchTxt'] },
      set(newValue) {
        this.$store.dispatch('share/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['share/currentPage'] },
      set(newValue) {
        this.$store.dispatch('share/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
  },
  async created() {
    try {
      await store.dispatch('share/setPageSel', { pageSel: this.$route.name }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await store.dispatch('share/fetchListView').catch(error => {
        this.showMsgErrorRequest(error)
      })
    } catch (err) {
      //
    }
  },
  beforeDestroy() {
    if (this.$refs.listviewScroll) {
      this.$refs.listviewScroll.destroy()
    }
  },
  methods: {
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    listviewFilterOrderBy(field) {
      this.$store.dispatch('share/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    setShowListingShareDetails(newValue) {
      this.$store.dispatch('share/setShowListingShareDetails', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    const SHARE_MODULE_NAME = 'share'

    if (!store.hasModule(SHARE_MODULE_NAME)) {
      store.registerModule(SHARE_MODULE_NAME, shareModule)

      onUnmounted(() => {
        if (store.hasModule(SHARE_MODULE_NAME)) store.unregisterModule(SHARE_MODULE_NAME)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss">
.email-application .content-area-wrapper .content-right{
    width: 100%!important;
}
</style>

<style lang="scss" scoped>
.email-app-list.space-bottom{
  padding-bottom: 2.8rem;

  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
}

.ecommerce-application .app-fixed-search {
  padding: 0 0 0 0!important;
}

.ecommerce-application .app-fixed-search input {
  margin-top: 0.4rem;
}

.ecommerce-application .email-user-list {
  height: calc( 100% - calc(4rem) )!important;
}

/* Desativa as animação devido que provoca erro no scroll to refresh */
.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

</style>
